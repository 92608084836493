import axios from './common/axios'

import {acceptGdpr, learnMoreGdpr} from './ux/gdpr'
import {subscriptionStatusVars} from './piano/sub-status'

const getPropensityScores = (customVars) => {
    /**
     * get propensity scores
     */
    customVars = customVars || {}
    const rmortonLogisticRegressionLatest = docCookies.getItem('rmorton.logistic_regression_latest');
    if (rmortonLogisticRegressionLatest) {
        customVars['rmorton_logistic_regression_latest'] = rmortonLogisticRegressionLatest
    }
    const rmortonLogisticRegressionMedHomeValueLatest = docCookies.getItem('rmorton.logistic_regression_med_home_value_latest');
    if (rmortonLogisticRegressionMedHomeValueLatest) {
        customVars['logistic_regression_med_home_value_latest'] = rmortonLogisticRegressionMedHomeValueLatest
    }
    return customVars
}

const submitHubSpotForm = (conversion) => {
    const hubspotFormData = window.hubspotFormData
    if (!hubspotFormData) {
        console.warn('Missing global variable "hubspotFormId"')
        return
    }
    const payload = {
        hubspotFormData,
        conversion,
    }
    axios.post('/piano/conversion/', payload)
}

const loginSuccessHandler = () => {
    // HRstateObj is defined in the Piano Expereince before the hotel name in the URL and page title are hidden
    // It'll contain the original path and title
    if (typeof window.HRstateObj == 'object') {
        history.replaceState(window.HRstateObj, window.HRstateObj.hotelTitle, window.HRstateObj.hotelPath);
        window.document.title = window.HRstateObj.hotelTitle;
    }
}

const submitHubSpotLoginForm = ({params}) => {
    axios.post('/piano/hubspot-login/', {email: params['email']})
}

const checkoutCompleteHandler = () => {
    UX.setInCheckout(false)
    if (window.location.pathname.match(/^\/join/) != null) {
        setTimeout(function () {
            window.location.href = '/home';
        }, 3000);
    } else {
        setTimeout(function () {
            window.location.reload();
        }, 3000);
    }
}

const checkoutCloseHandler = () => {
    UX.setInCheckout(false)
}

const checkoutStartHandler = () => {
    UX.setInCheckout(true)
}

const pwAssessHandler = () => {
    UX.pwAssess()
}

const loggedInHandler = (event) => {
    sendBasicGtmEvent('Piano_Logged_In', event)
    $('#loggedInMenu').removeClass('hidden')
    $('#logInBtn').addClass('hidden')
}

const sendBasicGtmEvent = (eventName, eventData) => {
     setSnowPlowUserId()
     dataLayer.push({
        'piano_event_json': buildEventJson(eventData),
        'event': eventName
    })
}

const gtmConversionTracking = (conversion) => {
    // This is used in GTM for Adwords Conversion Tracking
    setSnowPlowUserId()
    window.piano_conversion = conversion
    const orderIdStr = conversion.termConversionId
    const conversionJson = buildEventJson(conversion)
    if (conversion.hasOwnProperty('chargeAmount') && conversion.chargeAmount > 0) {
        // basic structured event for the converison
        dataLayer.push({
            'revenue': conversion.chargeAmount,
            'order_id': orderIdStr,
            'piano_event_json': conversionJson,
            'event': 'Piano_CC_Required_Trial'
        })

        // detailed ecommerce transaction for the conversion
        window.snowplow('addTrans',
            orderIdStr,           // order ID - required
            'checkoutComplete',  // affiliation or store name
            conversion.chargeAmount,          // total - required
            '',           // tax
            '',              // shipping
            '',       // city
            '',     // state or province
            '',            // country
            ''             // currency
        );

        // Add the item to the transaction
        window.snowplow('addItem',
            orderIdStr,           // order ID - required
            conversion.termId,           // SKU/code - required
            conversionJson,        // product name
            'membership',   // category or variation
            conversion.chargeAmount,          // unit price - required
            '1',              // quantity - required
            ''             // currency
        );
        
        // This pushes the tranaction to snowplow
        window.snowplow('trackTrans')
        
    } else {
        // if there's no payload object then it appears to be a registration without a payment for a term
        dataLayer.push({
            'order_id': orderIdStr,
            'piano_event_json': conversionJson,
            'event': 'Piano_No_CC_Trial'
        })
    }
}

const gtmCheckoutClose = (event) => {
    setSnowPlowUserId()
    dataLayer.push({
        'piano_checkout_close_reason': event.state,
        'piano_event_json': buildEventJson(event),
        'event': 'Piano_Close_Checkout'
    })
}

const gtmCheckoutError = (error) => {
    setSnowPlowUserId()
    dataLayer.push({
        'piano_checkout_error': error.message,
        'piano_event_json': buildEventJson(error),
        'event': 'Piano_Checkout_Error'
    })
}

const gtmLoginSuccess = (event) => {
    setSnowPlowUserId()
    dataLayer.push({
        'piano_login_includes_registration': event.registration,
        'piano_login_source': event.source,
        'piano_event_json': buildEventJson(event),
        'event': 'Piano_Login_Success'
    })
}

const gtmCheckoutSelectTerm = (event) => {
    setSnowPlowUserId()
    dataLayer.push({
        'piano_term_id': event.termId,
        'piano_term_name': event.termName,
        'piano_resource_id': event.resourceId,
        'piano_resource_name': event.resourceName,
        'piano_event_json': buildEventJson(event),
        'event': 'Piano_Checkout_Select_Term'
    })
}

const gtmCheckoutStateChange = (viewState) => {
    setSnowPlowUserId()
    dataLayer.push({
        'piano_checkout_state': JSON.stringify(viewState),
        'piano_event_json': buildEventJson(viewState),
        'event': 'Piano_State_Change'
    })
}

const gtmShowOffer = (event) => {
    setSnowPlowUserId()
    dataLayer.push({
        'piano_offer_id': event.offerId,
        'piano_template_id': event.templateId,
        'piano_display_mode': event.displayMode,
        'piano_term_id': event.termId, // doesn't appear to be 
        'piano_term_ids': JSON.stringify(event.termIds),
        'piano_event_json': buildEventJson(event),
        'event': 'Piano_Show_Offer'
    })
}

const gtmShowTemplate = (event) => {
    setSnowPlowUserId()
    dataLayer.push({
        'piano_offer_id': event.offerId,
        'piano_template_id': event.templateId,
        'piano_display_mode': event.displayMode,
        'piano_event_json': buildEventJson(event),
        'event': 'Piano_Show_Template'
    })
}

const gtmSubmitPayment = (event) => {
    setSnowPlowUserId()
    dataLayer.push({
        'piano_offer_id': event.offerId,
        'piano_term_id': event.term.termId,
        'piano_submit_payment_amount': event.term.chargeAmount,
        'piano_submit_payment_description': event.term.description,
        'piano_event_json': buildEventJson(event),
        'event': 'Piano_Submit_Payment'
    })
}

const gtmLoginRequired = (event) => {
    sendBasicGtmEvent('Piano_Login_Required', event)
}

const gtmExperienceExecute = (event) => {
    // this event has a huge json body with all experiences that 
    // Composer tested against the user.  Not all of them are relevent.
    // To make this useful, we parse all the events in the nested event.result.events array
    // and use userSegmentTrue type events as a proxy for identifying all
    // segments that fully executed for the user.
    setSnowPlowUserId()
    event.result.events.map(
        (e) => {
            if(e.eventType == 'userSegmentTrue'){
                let experience = event.result.experiences.find(
                    experience => experience.id == e.eventExecutionContext.experienceId
                )
                
                let dataLayerParams = {
                    'piano_experience_id': e.eventExecutionContext.experienceId,
                    'piano_experience_type': experience.type,
                    'piano_experience_title': experience.title,
                    'piano_experience_udpateDate': experience.updateDate,
                    'piano_experience_version': experience.version,
                    'piano_experience_segment_name': e.eventModuleParams.moduleName,
                    'piano_event_json': buildEventJson({
                        'accessList': event.accessList,  // user access list 
                        'eventModuleParams': e.eventModuleParams,  // details about the segment
                        'experience': experience // details about the experience
                    }),
                    'event': 'Piano_Experience_Execute'
                }
                dataLayer.push(dataLayerParams)
            }
        }
    )
}

const gtmMeterActive = (event) => {
    setSnowPlowUserId()
    dataLayer.push({
        'piano_meter_name': event.meterName,
        'piano_meter_total_views': event.totalViews,
        'piano_meter_views_within_limit': event.views,
        'piano_meter_max_views': event.maxViews,
        'piano_meter_views_left': event.viewsLeft,
        'piano_event_json': buildEventJson(event),
        'event': 'Piano_Meter_Active'
    })
}

const gtmMeterExpired = (event) => {
    setSnowPlowUserId()
    dataLayer.push({
        'piano_meter_name': event.meterName,
        'piano_meter_total_views': event.totalViews,
        'piano_meter_views_within_limit': event.views,
        'piano_meter_max_views': event.maxViews,
        'piano_meter_views_left': event.viewsLeft,
        'piano_event_json': buildEventJson(event),
        'event': 'Piano_Meter_Expired'
    })
}

const loggedOutHandler = (event) => {
    setSnowPlowUserId()
    sendBasicGtmEvent('Piano_Logged_Out', event)
    window.location.reload();
}

let customVars = {}

const addLoginAndRegisterEvents = () => {
    $('[data-login]').click(function () {
        window.tp.pianoId.show({screen: 'login'})
    });

    $('[data-register]').click(function () {
        window.tp.pianoId.show({screen: 'register'})
    });

    $('[data-logout]').click(function () {
        window.tp.pianoId.logout()
    })
}

const pushCustomVariables = (tp) => {
    customVars = getPropensityScores(customVars)
    for (const [key, value] of Object.entries(customVars)) {
        tp.push(['setCustomVariable', key, value])
    }
}

const sendEvent = (eventType, data) => {
    if (eventType == 'experienceExecute'){
        // The `data` payload for this event contains a circular reference, where the last element of
        // data.result.events is the `data` object itself. This means the JSON.stringify operation fails with
        // a circular reference error. To avoid that, we just filter that element out of the events array to
        // break the circle.
        let data_copy =  Object.assign({}, data)
        data_copy.result.events = data_copy.result.events.filter(ev => ev.eventType != "experienceExecute")
        axios.post('/webhooks/piano-js/', {'event': eventType, 'payload': data_copy})
    } else{
        axios.post('/webhooks/piano-js/', {'event': eventType, 'payload': data})
    }
}

let customCallbacks = {
    
    // These are Piano Composer and VX Callbacks 
    // https://docs.piano.io/callbacks/
    checkoutComplete: [submitHubSpotForm, gtmConversionTracking, checkoutCompleteHandler], // checkoutCompleteHandler makes a redirect so keep it at the end
    checkoutClose: [checkoutCloseHandler, gtmCheckoutClose],
    checkoutError: [pwAssessHandler, gtmCheckoutError],
    startCheckout: [checkoutStartHandler, (event) => sendBasicGtmEvent('Piano_Start_Checkout', event)],
    checkoutSelectTerm: [gtmCheckoutSelectTerm],
    checkoutStateChange: [gtmCheckoutStateChange],
    showOffer: [gtmShowOffer],
    showTemplate: [gtmShowTemplate],
    submitPayment: [gtmSubmitPayment],
    loginRequired: [gtmLoginRequired],
    experienceExecute: [gtmExperienceExecute],
    meterActive: [gtmMeterActive],
    meterExpired: [gtmMeterExpired],
    loginSuccess: [submitHubSpotLoginForm, gtmLoginSuccess, loginSuccessHandler, pwAssessHandler], // pwAssessHandler makes a redirect so keep it at the end
}

const addHandlers = (tp) => {
    const events = [
        'checkoutComplete', 
        'loginSuccess',
        'checkoutClose', 
        'checkoutError', 
        'startCheckout',         
        'checkoutSelectTerm', 
        'checkoutStateChange', 
        'showOffer', 
        'showTemplate', 
        'submitPayment',
        'loginRequired',
        'experienceExecute', 
        'meterActive', 
        'meterExpired',
        'loginSuccess'
    ]
    events.forEach(eventType => {
        tp.push(['addHandler', eventType, (data) => {
            sendEvent(eventType, data)
            if (Array.isArray(customCallbacks[eventType])) {
                customCallbacks[eventType].forEach(callback => {
                    callback(data)
                })
            }
        }])
    })
    tp.push(['addHandler', 'customEvent', (event) => {
        switch (event.eventName) {
            case 'gdprAccepted':
                acceptGdpr()
                break
            case 'learnMoreGdpr':
                learnMoreGdpr()
                break
            case 'autoRenewUpdated':
                console.log('[autoRenewUpdated]',event)
                break
        }
    }])

    // getting around short comings in Piano My Account template external-event directive support
    // This listens for messages from the native postMessage method on window
    // which can be sent from the piano iframe using piano templates.
    window.addEventListener('message', function (event) {
        if (event.data.hasOwnProperty('eventName')) {
            switch (event.data.eventName) {
                case 'autoRenewUpdated':
                    console.log('[message received] sending autoRenewUpdated snowplow event: ', event.data.value)
                    window.snowplow(
                        'trackStructEvent',
                        'Autorenew', // Category
                        'Toggle', // Action
                        event.data.value, // Label
                        '', // Property
                        ''// Value
                    )
                    break
                case 'manualRenewal':
                    console.log('[message received] sending manualRenewal snowplow event')
                    window.snowplow(
                          'trackStructEvent',
                          'Manual Renewal', // Category
                          'My Account', // Action
                          event.data.value, // Label
                          '', // Property
                          ''// Value
                    )
                    break
            }
        }
    })
}

const showInlineForm = (screen, containerSelector) => {
    window.tp.pianoId.show({displayMode: 'inline', screen, containerSelector})
}

const showInlineAccountPage = (containerSelector) => {
    window.tp.myaccount.show({displayMode: 'inline', containerSelector})
}

let onInitResolve, onInitReject
const onInitPromise = new Promise((resolve, reject) => {
    onInitResolve = resolve
    onInitReject = reject
    if (isInit) {
        resolve()
        return
    }
    setTimeout(() => {
        if (!isInit) {
            reject('Not initialized')
        }
    }, 20000)
})

let isInit = false
const setInit = (value) => {
    isInit = value
    if (isInit) {
        onInitResolve()
    } else {
        onInitReject('Not initialized')
    }
}

const onInit = () => {
    if (isInit) {
        onInitResolve()
    }
    return onInitPromise
}

const init = function () {
    let tp = window.tp
    setInit(true)
    addHandlers(tp)
    tp.enableGACrossDomainLinking('UA-1769425-2')
    
    tp.pianoId.init({
        // some callback handlers don't appear to bind unless you do it init()
        // These for example....
        loggedIn: loggedInHandler,
        loggedOut: loggedOutHandler,
        profileUpdate: (event) => sendBasicGtmEvent('Piano_Profile_Update', event),
        registrationSuccess: (event) => sendBasicGtmEvent('Piano_Registration_Success', event),
        langChange: (event) => sendBasicGtmEvent('Piano_Lang_Change', event),
        loginDisplayed: (event) => sendBasicGtmEvent('Piano_Login_Displayed', event),
        registerDisplayed: (event) => sendBasicGtmEvent('Piano_Register_Displayed', event),
        loginFailed: (event) => sendBasicGtmEvent('Piano_Login_Failed', event),
        registrationFailed: (event) => sendBasicGtmEvent('Piano_Registration_Failed', event),
    })

    pushCustomVariables(tp)
    // sub status requires an async API call, so we can't init the experiences until it's done.
    subscriptionStatusVars(tp).finally(_ => tp.experience.init())
    
    setSnowPlowUserId()
}

const passwordReset = (data) => {
    if (!window.tp.user.isUserValid()) {
        // If URL has reset_token parameter
        let tokenMatch = location.search.match(/reset_token=([A-Za-z0-9]+)/);
        if (tokenMatch) {
            // Get value of the token
            let token = tokenMatch[1];
            // Present password reset form with the found token
            window.tp.pianoId.show({'resetPasswordToken': token});
        }
    }
}

const initialize = ({tags, customCallbacks, customVariables, aid}) => {
    /**
     * The basic integration code
     * https://docs.piano.io/piano-javascript
     * https://docs.piano.io/track/implementing-piano/#productionjs
     */

    /**
     * initializing the tp object and passing custom stuff
     * https://docs.piano.io/track/implementing-piano/#menu1008
     */
    tags = tags || []
    let tp = window['tp'] || []

    /**
     * The setAID and setEndpoint calls were previously within the experiences load script,
     * but that was replaced with custom code since it would init the experiences before all the custom vars
     * were loaded. So we now set them here, with AID passed in from sitebase during initialization.
     *
     * The experience initialization now occurs within the `init` function.
     */

    tp.push(["setAid", aid])
    tp.push(["setEndpoint", 'https://buy.tinypass.com/api/v3'])
    addCustomCallbacks(customCallbacks)
    addCustomVariables(customVariables)
    tp.push(['setTags', tags])
    tp.push(['setDebug', false])
    tp.push(['setUsePianoIdUserProvider', true])
    tp.push(['init', init])
    
    addLoginAndRegisterEvents()
    window.tp = tp
}

const setSnowPlowUserId = () => {
    const user = window.tp.pianoId.getUser()
    if(user) {
        if(window.hasOwnProperty('snowplow')) {
            window.snowplow('setUserId', user.uid)
        } else {
            console.error('[user] No snowplow object!');
        }
    }
}

const addCustomCallbacks = (callbacks) => {
    callbacks = callbacks || {}
    for (let [key, callback] of Object.entries(callbacks)) {
        if (customCallbacks[key] === undefined) {
            customCallbacks[key] = []
        }
        if (typeof callback !== 'function') {
            throw Error(`Callback for key "${key}" is not a function`)
        }
        customCallbacks[key].push(callback)
    }
}

const addCustomVariables = (variables) => {
    customVars = Object.assign(customVars, variables)
}

const buildEventJson = (event) => {
    let eventJson = {
        'event': event,
        'customVars': customVars
    }
    return JSON.stringify(eventJson)
}

export {
    customVars,
    addCustomCallbacks,
    addCustomVariables,
    initialize,
    onInit,
    showInlineForm,
    showInlineAccountPage,
    passwordReset,
    subscriptionStatusVars,
    setSnowPlowUserId
}
