/**
 * Generate a pre-configured axios instance for use within our new JS.
 *
 * This sets the Content Type to json and injects the CSRF token.
 */
import Axios from 'axios'
import * as Cookies from 'js-cookie'

const axios = Axios.create({
    headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        'Content-Type': 'application/json',
    }
});

export default axios;
