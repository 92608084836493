import axios from '../common/axios'

const autoRenewUpdatedEvent = 'autoRenewUpdated';
const autoRenewStorageKey = 'hr:autoRenew';

let localTp;

function checkAutoRenewState(activeSub) {
    if (!window.sessionStorage)
        return;

    let autorenew = activeSub.auto_renew;
    let local_state = sessionStorage.getItem(autoRenewStorageKey);
    if (local_state !== null) {
        if (local_state != JSON.stringify(autorenew)) {
            let payload = {
                state: autorenew,
                ts: moment().unix(),
                uid: tp.pianoId.getUser().uid,
                subId: activeSub.subscription_id,
            };
            axios.post('/webhooks/piano-js/', {event: autoRenewUpdatedEvent, payload});
            sessionStorage.setItem(autoRenewStorageKey, autorenew);
            $(document).trigger('hr:autoRenew', payload);
        }
    } else {
        sessionStorage.setItem(autoRenewStorageKey, autorenew);
    }
}

function subCallback(response) {
    let activeSub = {};
    if (response.code === 0 && response.count) {
        let filteredSubs = response.subscriptions.filter((sub) => sub.status == 'active');
        activeSub = filteredSubs[0];
    }

    window.activeSub = activeSub;  // for utility

    if (typeof activeSub == 'object') {
        for (var attribute in activeSub) {
            if ( ['string','boolean','number'].indexOf(typeof activeSub[attribute]) > -1) {
                localTp.push(["setCustomVariable", attribute, activeSub[attribute]]);
            }
            if (attribute == 'payment_billing_plan_description') {
                localTp.push(["setCustomVariable", "billing_plan_desc", activeSub.payment_billing_plan_description]);
            }
        }

        if (typeof activeSub.term == 'object') {
            localTp.push(["setCustomVariable", "term_name", activeSub.term.name]);
            localTp.push(["setCustomVariable", "term_type", activeSub.term.type]);
        }

        if (typeof activeSub.auto_renew != 'undefined') {
            checkAutoRenewState(activeSub);
        }
    }
}

function subscriptionStatusVars(tp) {
    localTp = tp;
    return new Promise(resolve =>
        localTp.api.callApi('/subscription/list', {}, response => resolve(subCallback(response)))
    );
}

export {
    subscriptionStatusVars,
}
