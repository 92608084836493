const acceptGdpr = () => {
    const date = new Date()
    const minutes = 999999
    date.setTime(date.getTime() + (minutes * 60 * 1000))
    const expires = `; expires=${date.toGMTString()}`

    document.cookie = `_pc_gdprAccepted=true; ${expires}; path=/`
}

const learnMoreGdpr = () => {
    window.location = '/info/terms-of-service/'
}

export {
    acceptGdpr,
    learnMoreGdpr,
}
